<template>
    <div class="flex flex-1 flex-col card card-f-h">
        <ConfirmationModal codeConfirmation @success="actionSuccess" />

        <ModalAddAddress
            :visible="modalAddAddressVisible"
            @refresh="actionSuccess"
            @close="modalAddAddressVisible = false"
        />

        <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
            <p>
                {{ $t('clients.all_addresses') }} ({{ addressesData.length }})
            </p>
            <el-button type="primary" @click="showModal">
                {{ $t('clients.add_address') }}
            </el-button>
        </header>

        <Table
            :tableData="addressesData"
            @handleDelete="handleDelete"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>
import Api from './addresses.api';

export default {
    components: {
        ModalAddAddress: () => import(/* webpackChunkName: "ClientAddresses/AddressesModalAdd" */ './AddressesModalAdd'),
        Table:           () => import(/* webpackChunkName: "ClientAddresses/AddressesTable" */ './AddressesTable'),
    },

    data() {
        return {
            addressesData:          [],
            clientUuid:             this.$route.params.uuid,
            modalAddAddressVisible: false,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_addresses');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.addresses');
            try {
                this.addressesData = await Api.getAll(this.clientUuid);
                    console.log('🚀 ~ file: Addresses.vue ~ line 59 ~ getAll ~ this.addressesData.length', this.addressesData.length);
                if (this.addressesData.length === 1) {
                    this.$router.push({ name: 'client_address_details', params: { uuid: this.clientUuid, address_uuid: this.addressesData[0].uuid } });
                }
            } finally {
                this.$wait.end('loading.addresses');
                this.$wait.end('loading.initial_addresses');
            }
        },

        showModal() {
            this.modalAddAddressVisible = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(uuid),
                actionType: 'destroy',
            });
        },

        actionSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
