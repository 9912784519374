export default {
    async getAll(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/addresses`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: addresses.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async getDetails(clientUuid, addressUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/address/${addressUuid}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: addresses.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async create(clientUuid, data) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses`, {
                title:                   data.title,
                status:                  data.status,
                client_uuid:             clientUuid,
                street:                  data.street,
                post_code:               data.post_code,
                city:                    data.city,
                co:                      data.co,
                telephone:               data.telephone,
                door_code:               data.door_code,
                unblock_alarm_code:      data.unblock_alarm_code,
                block_alarm_code:        data.block_alarm_code,
                alarm_code_instructions: data.alarm_code_instructions,
                map_lat:                 data.map_lat,
                map_lng:                 data.map_lng,
                geofence_radius:         data.geofence_radius,
                apartment_type:          data.apartment_type,
                square_meters:           data.square_meters,
            });
        } catch (error) {
            console.log('🚀 ~ file: addresses.api.js ~ create ~ error', error);
            throw error;
        }
    },

    async update(addressUuid, addressData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses/${addressUuid}`, {
                title:                   addressData.title,
                status:                  addressData.status,
                street:                  addressData.street,
                post_code:               addressData.post_code,
                city:                    addressData.city,
                co:                      addressData.co,
                floor:                      addressData.floor,
                telephone:               addressData.telephone,
                door_code:               addressData.door_code,
                unblock_alarm_code:      addressData.unblock_alarm_code,
                block_alarm_code:        addressData.block_alarm_code,
                alarm_code_instructions: addressData.alarm_code_instructions,
                map_lat:                 addressData.map_lat,
                map_lng:                 addressData.map_lng,
                geofence_radius:         addressData.geofence_radius,
                apartment_type:          addressData.apartment_type,
                square_meters:           addressData.square_meters,
            });
        } catch (error) {
            console.log('🚀 ~ file: addresses.api.js ~ update ~ error', error);
            throw error;
        }
    },

    destroyURL(uuid) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/addresses/${uuid}`;
    },
};
